var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-col', {
    attrs: {
      "md": "12 d-flex"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between profile-maindv"
  }, [_c('div', {
    staticClass: "preview-box"
  }, [_vm.userData && _vm.userData.extendedAttributes && _vm.userData.extendedAttributes.profilePhoto ? _c('img', {
    staticClass: "mr-3",
    attrs: {
      "src": _vm.userData.extendedAttributes.profilePhoto,
      "width": "100px",
      "alt": ""
    }
  }) : _c('img', {
    staticClass: "mr-3",
    attrs: {
      "src": "/img/profile.png",
      "width": "100px",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "profile-detail mr-2 ml-2"
  }, [_vm.userInfo.name.first === _vm.userInfo.name.last ? _c('h5', {
    staticClass: "mb-3"
  }, [_vm._v(" " + _vm._s("".concat(_vm.userInfo.name.first)) + " ")]) : _c('h5', {
    staticClass: "mb-3"
  }, [_vm._v(" " + _vm._s("".concat(_vm.userInfo.name.first, " ").concat(_vm.userInfo.name.last)) + " ")]), _c('div', {
    staticClass: "d-flex"
  }, [_vm.userData && _vm.userData.extendedAttributes && _vm.userData.extendedAttributes.specialMember ? _c('div', [_c('p', [_c('img', {
    staticClass: "mr-1",
    attrs: {
      "src": "/img/special_member.png",
      "width": "20px",
      "alt": ""
    }
  }), _vm._v(" Special Member ")])]) : _vm._e()]), _c('p', [_c('img', {
    staticClass: "mr-1",
    attrs: {
      "src": "/img/small_teka_logo.png",
      "width": "16px",
      "alt": ""
    }
  }), _vm._v(" TID: " + _vm._s(_vm.userInfo ? _vm.userInfo.customerNo : "") + " ")]), _vm.userInfo && _vm.userInfo.authentication && _vm.userInfo.authentication.phone ? _c('p', {
    staticClass: "mb-2"
  }, [_c('b-icon', {
    attrs: {
      "icon": "telephone-fill",
      "aria-label": "Help"
    }
  }), _vm._v(" " + _vm._s(_vm.userInfo && _vm.userInfo.authentication ? _vm.userInfo.authentication.phone : "") + " ")], 1) : _vm._e(), Object.keys(_vm.defaultAddress).length !== 0 ? _c('p', {
    staticClass: "mb-2"
  }, [_c('b-icon', {
    attrs: {
      "icon": "geo-alt-fill",
      "aria-label": "Help"
    }
  }), [_vm._v(" " + _vm._s(_vm.defaultAddress.street2) + ", " + _vm._s(_vm.defaultAddress.city) + ", " + _vm._s(_vm.defaultAddress.state) + ", " + _vm._s(_vm.defaultAddress.zip) + " ")]], 2) : _vm._e()]), _vm.editKey ? _c('div', {
    staticClass: "edit-icon"
  }, [_c('a', {
    staticClass: "d-flex text-dark",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function click($event) {
        return _vm.$bvModal.show('modal-profile');
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "pencil-fill",
      "aria-label": "Help"
    }
  }), _vm._v(" Edit")], 1), [_c('div', [_c('b-modal', {
    attrs: {
      "hide-footer": "",
      "hide-header": "",
      "id": "modal-profile",
      "size": "lg",
      "title": "Edit Profile"
    }
  }, [_c('v-form', [_c('v-container', {
    staticClass: "p-3"
  }, [_c('v-row', {
    staticClass: "mb-2"
  }, [_c('h3', [_vm._v("Edit Profile")])]), _c('v-row', [_c('b-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "profile-img-upload"
  }, [_c('div', {
    staticClass: "preview-box"
  }, [!_vm.profileImageAvailable ? _c('img', {
    attrs: {
      "src": "/img/profile.png",
      "alt": ""
    }
  }) : _c('img', {
    attrs: {
      "src": _vm.selectedImageFile,
      "alt": ""
    }
  })]), _c('input', {
    ref: "fileUpload",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file"
    },
    on: {
      "change": _vm.imageSelection
    }
  })])])], 1), _c('v-row', [_c('b-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "12"
    }
  }, [_c('button', {
    staticClass: "mr-2 edit-btn set-img",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.fileUpload.click();
      }
    }
  }, [_c('img', {
    staticClass: "upload-icon",
    attrs: {
      "src": "/img/edit.png",
      "alt": ""
    }
  }), _c('p', [_vm._v("Change")])]), _c('button', {
    staticClass: "ml-2 delete-btn set-img",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.removeProfileImage
    }
  }, [_c('img', {
    staticClass: "upload-icon",
    attrs: {
      "src": "/img/delete.png",
      "alt": ""
    }
  }), _c('p', [_vm._v("Remove")])])])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "First Name"
    },
    model: {
      value: _vm.first,
      callback: function callback($$v) {
        _vm.first = $$v;
      },
      expression: "first"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Last Name",
      "placeholder": "Placeholder"
    },
    model: {
      value: _vm.last,
      callback: function callback($$v) {
        _vm.last = $$v;
      },
      expression: "last"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Mobile Number",
      "readonly": "",
      "placeholder": "Placeholder"
    },
    model: {
      value: _vm.phone,
      callback: function callback($$v) {
        _vm.phone = $$v;
      },
      expression: "phone"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-select', {
    staticClass: "mt-1",
    attrs: {
      "options": _vm.options
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1), _c('b-col', {
    staticClass: "text-center mt-3"
  }, [_c('button', {
    staticClass: "btn btn-outline-primary mr-3 pr-5 pl-5",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.$bvModal.hide('modal-profile');
      }
    }
  }, [_c('span', {
    staticClass: "button-text"
  }), _vm._v(" Cancel ")]), _c('button', {
    staticClass: "btn btn-primary ml-3",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.updateProfile
    }
  }, [_c('span', {
    staticClass: "button-text pr-5 pl-5"
  }, [_vm._v("Save")])])])], 1)], 1)], 1)], 1)], 1)]], 2) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }