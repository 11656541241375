<template>
  <!-- <div class="show-edit-profile"> -->
  <b-col md="12 d-flex">
    <div class="d-flex justify-content-between profile-maindv">
      <div class="preview-box">
        <img
          :src="userData.extendedAttributes.profilePhoto"
          width="100px"
          class="mr-3"
          alt
          v-if="
            userData &&
            userData.extendedAttributes &&
            userData.extendedAttributes.profilePhoto
          "
        />
        <img src="/img/profile.png" width="100px" class="mr-3" alt v-else />
      </div>
      <div class="profile-detail mr-2 ml-2">
        <h5 class="mb-3" v-if="userInfo.name.first === userInfo.name.last">
          {{ `${userInfo.name.first}` }}
        </h5>
        <h5 class="mb-3" v-else>
          {{ `${userInfo.name.first} ${userInfo.name.last}` }}
        </h5>
        <div class="d-flex">
          <!-- <div v-if="userInfo && userInfo.metaInfo && userInfo.metaInfo.badges[0]" class="mr-3">
            <img src="/img/influencer.png" width="20px" class="mr-1" alt />

            {{ userInfo && userInfo.metaInfo ? userInfo.metaInfo.badges[0] : "" }}
            
          </div> -->
          <div
            v-if="
              userData &&
              userData.extendedAttributes &&
              userData.extendedAttributes.specialMember
            "
          >
            <p>
              <img
                src="/img/special_member.png"
                width="20px"
                class="mr-1"
                alt
              />
              Special Member
            </p>
          </div>
        </div>
        <p>
          <img src="/img/small_teka_logo.png" width="16px" class="mr-1" alt />
          TID: {{ userInfo ? userInfo.customerNo : "" }}
        </p>
        <p
          class="mb-2"
          v-if="
            userInfo && userInfo.authentication && userInfo.authentication.phone
          "
        >
          <b-icon icon="telephone-fill" aria-label="Help"></b-icon>
          {{
            userInfo && userInfo.authentication
              ? userInfo.authentication.phone
              : ""
          }}
        </p>
        <!-- <p class="mb-2">
          <b-icon icon="envelope-fill" aria-label="Help"></b-icon>
          {{ userInfo.authentication.email }}
        </p> -->
        <p v-if="Object.keys(defaultAddress).length !== 0" class="mb-2">
          <b-icon icon="geo-alt-fill" aria-label="Help"></b-icon>
          <template>
            {{ defaultAddress.street2 }}, {{ defaultAddress.city }},
            {{ defaultAddress.state }}, {{ defaultAddress.zip }}
          </template>
        </p>
      </div>
      <div v-if="editKey" class="edit-icon">
        <a
          @click="$bvModal.show('modal-profile')"
          style="cursor: pointer"
          class="d-flex text-dark"
        >
          <b-icon icon="pencil-fill" aria-label="Help"></b-icon> Edit</a
        >
        <template>
          <div>
            <b-modal
              hide-footer
              hide-header
              id="modal-profile"
              size="lg"
              title="Edit Profile"
            >
              <v-form>
                <v-container class="p-3">
                  <v-row class="mb-2">
                    <h3>Edit Profile</h3>
                  </v-row>
                  <v-row>
                    <b-col cols="12" sm="12" md="12" class="text-center">
                      <div class="profile-img-upload">
                        <div class="preview-box">
                          <img
                            src="/img/profile.png"
                            alt
                            v-if="!profileImageAvailable"
                          />
                          <img :src="selectedImageFile" alt v-else />
                        </div>
                        <input
                          ref="fileUpload"
                          type="file"
                          style="display: none"
                          @change="imageSelection"
                        />
                      </div>
                    </b-col>
                  </v-row>
                  <v-row>
                    <b-col cols="12" sm="12" md="12" class="text-center">
                      <button
                        type="button"
                        class="mr-2 edit-btn set-img"
                        @click="$refs.fileUpload.click()"
                      >
                        <img src="/img/edit.png" class="upload-icon" alt />
                        <p>Change</p>
                      </button>
                      <button
                        type="button"
                        class="ml-2 delete-btn set-img"
                        @click="removeProfileImage"
                      >
                        <img src="/img/delete.png" class="upload-icon" alt />
                        <p>Remove</p>
                      </button>
                    </b-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="first"
                        label="First Name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="last"
                        label="Last Name"
                        placeholder="Placeholder"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        label="Mobile Number"
                        readonly
                        v-model="phone"
                        placeholder="Placeholder"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <b-form-select
                        v-model="selected"
                        :options="options"
                        class="mt-1"
                      ></b-form-select>
                    </v-col>
                    <!-- <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        label="Email"
                        v-model="userInfo.authentication.email"
                        placeholder="Placeholder"
                      ></v-text-field>
                    </v-col> -->

                    <!-- <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        label="Address 1"
                        placeholder="Placeholder"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        label="Address 2"
                        placeholder="Placeholder"
                      ></v-text-field>
                    </v-col> -->
                    <b-col class="text-center mt-3">
                      <button
                        type="button"
                        class="btn btn-outline-primary mr-3 pr-5 pl-5"
                        @click="$bvModal.hide('modal-profile')"
                      >
                        <span class="button-text"></span> Cancel
                      </button>
                      <button
                        @click="updateProfile"
                        type="button"
                        class="btn btn-primary ml-3"
                      >
                        <span class="button-text pr-5 pl-5">Save</span>
                      </button>
                    </b-col>
                  </v-row>
                </v-container>
              </v-form>
            </b-modal>
          </div>
        </template>
      </div>
    </div>
  </b-col>
  <!-- </div> -->
</template>

<script>
// import Cropper from "cropperjs";
import { mapState } from "vuex";
export default {
  name: "show-edit-profile",
  data() {
    return {
      first: "",
      last: "",
      phone: "",
      showImageEdit: false,
      selectedImageFile: "",
      profileImageAvailable: false,
      uploadedImage: "",
      selected: null,
      options: [
        { text: "Select Your Gender", value: null, disabled: true },
        { text: "Male", value: "Male" },
        { text: "Female", value: "Female" },
      ],
      profileImageDelete: false,
      cropper: {},
      image: {},
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.auth.userInfo,
      defaultAddress: (state) => state.address.defaultAddress,
      userData: (state) => state.profile.userData,
    }),
  },
  props: {
    editKey: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    imageSelection(event) {
      this.profileImageAvailable = true;
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.selectedImageFile = reader.result;
        sessionStorage.setItem("profilePhoto", reader.result);
      };
    },
    async updateProfile() {
      let payload = {
        name: { first: this.first, last: this.last },
        extendedAttributes: {
          profilePhoto: sessionStorage.getItem("profilePhoto"),
          gender: this.selected,
        },
        userName: this.userInfo?.authentication?.userName || "",
        email: this.userInfo?.authentication?.email || "",
        phone: this.phone,
        securityQuestions:
          this.userInfo?.authentication?.securityQuestions || "",
      };
      if (this.profileImageDelete) {
        payload.extendedAttributes.profilePhoto = "none";
      }
      await this.$store
        .dispatch("profile/updateProfileAction", payload)
        .then(async (result) => {
          localStorage.setItem(
            "eparisheva:userInfo",
            JSON.stringify(result.data.response)
          );
          this.userInfo.name.first = this.first;
          this.userInfo.name.last = this.last;
          this.userInfo.authentication.phone = this.phone;
          this.userInfo.extendedAttributes.gender = this.selected;
          if (this.profileImageDelete) {
            this.profileImageAvailable = false;
            this.profileImageDelete = false;
          }
        });
      await this.$store.dispatch("profile/getCustomerData", this.userInfo._id);
      this.showPopUp();
    },
    removeProfileImage() {
      this.$bvModal
        .msgBoxConfirm("Do you want to remove this image?", {
          title: "Please Confirm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
        .then((res) => {
          console.log(res);
          this.profileImageDelete = true;
          this.showImagePath = "/img/profile.png";
          localStorage.removeItem("profilePhoto");
          this.updateProfile();
        });
    },
    showPopUp() {
      this.$swal({
        text: "Success",
        timer: 1100,
      });
      this.$bvModal.hide("modal-profile");
    },
  },
  async mounted() {
    await this.$store.dispatch("profile/getCustomerData", this.userInfo._id);
    this.first = this.userInfo?.name?.first || "";
    this.last = this.userInfo?.name?.last || "";
    this.phone = this.userInfo?.authentication?.phone || "";
    this.selected = this.userInfo?.extendedAttributes?.gender || "";
    if (this.userData?.extendedAttributes?.profilePhoto) {
      this.profileImageAvailable = true;
      this.uploadedImage = this.userData?.extendedAttributes?.profilePhoto;
      this.selectedImageFile = this.userData?.extendedAttributes?.profilePhoto;
    }
  },
};
</script>
<style scoped>
#modal-profile h3 {
  color: #fd7e14;
}
::v-deep #modal-profile .mdi-menu-down {
  border: none;
  box-shadow: none;
  background-color: #fff !important;
}
::v-deep #modal-profile .custom-select {
  border: none;
  border-bottom: 1px solid #717171;
  border-radius: 0px;
}
.profile-img-upload {
  position: relative;
}
.edit-img-box {
  height: 480px;
  width: 480px;
}
.profile-img-upload .upload-icon {
  z-index: 1;
  position: absolute;
  top: 0;
  margin-left: 15px;
  cursor: pointer;
}
.preview-box img {
  height: 110px;
  width: 110px;
  border-radius: 55px;
  /* border: 3px solid #272727; */
  margin: auto;
}
.set-img {
  width: 100px;
  padding: 3px;
  border-radius: 8px;
}
.set-img p {
  display: inline-block;
  margin-bottom: 0px;
  margin-left: 6px;
  font-size: 14px;
  font-weight: 600;
}
.edit-btn {
  border: 2px solid #272727;
}
.delete-btn {
  border: 2px solid #ff6d59;
  color: #ff6d59;
}
.profile-maindv {
  background: #f8f8f8;
  padding: 15px;
  border-radius: 15px;
  border: 1px solid #efefef;
  width: 100%;
}
.edit-icon svg {
  margin-right: 7px;
  margin-top: 4px;
}
</style>
